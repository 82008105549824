const language = {
  common: {
    site_title: "DB Traders",
  },
  menu: {
    gifts: "GIFTS",
    makeup: "MAKE UP",
    mobile_accessories: "MOBILE ACCESSORIES",
  },
};

export default language;
