import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import language from "../../config/data";

export default function Header() {
  return (
    <Navbar bg="white" expand="lg">
      <Container className="border-bottom py-2">
        <Navbar.Brand href="#home">{language.common.site_title}</Navbar.Brand>
        {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
        {/* <Navbar.Collapse id="basic-navbar-nav"> */}
        {/* <Nav className="justify-content-center w-100">
            <Nav.Link className="menu-item" href="#home">
              {language.menu.gifts}
            </Nav.Link>
            <Nav.Link className="menu-item" href="#link">
              {language.menu.makeup}
            </Nav.Link>
            <Nav.Link className="menu-item" href="#link">
              {language.menu.mobile_accessories}
            </Nav.Link>
          </Nav> */}
        {/* <Form className="d-flex">
            <Form.Control
              type="search"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
            />
            <Button variant="outline-success">Search</Button>
          </Form> */}
        {/* </Navbar.Collapse> */}
      </Container>
    </Navbar>
  );
}
