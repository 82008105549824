import Carousel from "react-bootstrap/Carousel";
import Container from "react-bootstrap/esm/Container";
import banner1 from "../../assets/images/banner1.jpg";

export default function Slider() {
  const items = 20;
  const style = {
    width: "auto",
    height: "auto",
    maxWidth: "100%",
    maxHeight: "80vh",
  };
  return (
    <Container>
      <Carousel variant="dark" indicators={false}>
        {[...Array(20)].map((x, i) => (
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={"images/slider/" + (i + 1) + ".jpg"}
              alt={i + " slide"}
              style={style}
            />
            {/* <Carousel.Caption>
              <h3>First slide label</h3>
              <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
            </Carousel.Caption> */}
          </Carousel.Item>
        ))}
      </Carousel>
    </Container>
  );
}

/**
 * 
 * 
 *         <Carousel.Item>
          <img className="d-block w-100" src={banner1} alt="First slide" />
          <Carousel.Caption>
            <h3>First slide label</h3>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="holder.js/800x400?text=Second slide&bg=282c34"
            alt="Second slide"
          />

          <Carousel.Caption>
            <h3>Second slide label</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="holder.js/800x400?text=Third slide&bg=20232a"
            alt="Third slide"
          />

          <Carousel.Caption>
            <h3>Third slide label</h3>
            <p>
              Praesent commodo cursus magna, vel scelerisque nisl consectetur.
            </p>
          </Carousel.Caption>
        </Carousel.Item>

 */
