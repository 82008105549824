import Header from "../components/header/Header";
import Slider from "../components/slider/Slider";

export default function Home() {
  return (
    <>
      <Header />
      <Slider />
      <br />
      <hr />
      <div className="container p-3 my-4">
        <h3>
          <b>Email ID: </b>dbtraders01@gmail.com
        </h3>
        <h4>
          <b>Phone:</b> +91 8011673333
        </h4>
        <h4>
          <b>Address:</b> A.G Office Road, Beltola, Guwahati
        </h4>
        <br />
        <br />
      </div>
    </>
  );
}
